<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <h5 class="app-label section-label">
        <span class="align-middle">{{ $t('Partner') }}</span>
      </h5>
      <v-select
        id="register-respDataPartner"
        v-model="profilePartnerId"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="firstName"
        :options="respDataPartner"
        :reduce="(respDataPartner) => respDataPartner._id"
        :filterable="false"
        class="select-size-lg"
        @search="onSearchPartner"
      >
        <div
          slot="list-footer"
          class="m-1"
        >
          <b-row>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                block
                :disabled="currentPagePartner <= 1 ? true :false"
                @click="nextPagePartner(currentPagePartner -1)"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                />
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                disabled
              >
                {{ currentPagePartner }}
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                block
                :disabled="(currentPagePartner * perPagePartner) >= queriedItemsPartner ? true :false"
                @click="nextPagePartner(currentPagePartner + 1)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                />
              </b-button>
            </b-col>
          </b-row>

        </div>
      </v-select>
      <div class="mt-2">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Calendars') }}</span>
        </h5>
        <!-- <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          {{ $t('View All') }}
        </b-form-checkbox> -->
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="(item, index) in calendarOptions"
              :key="index"
              name="event-filter"
              :value="item._id"
              class="mb-1"
              :class="`custom-control-${item.colorName}`"
            >
              {{ showFromCurrentLanguage(item.name) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import debounce from 'debounce'

// import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    BCol,
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    addNew: {
      type: Function,
      require: true,
    },
    storeModuleName: {
      type: String,
      required: true,
    },
    refetchEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchPartner: '',
      currentPagePartner: 1,
      perPagePartner: 10,
    }
  },
  computed: {
    calendarOptions() {
      return store.state[this.storeModuleName].calendarOptions
    },
    selectedCalendars: {
      get() {
        return store.state[this.storeModuleName].selectedCalendars
      },
      set(value) {
        store.commit(`${this.storeModuleName}/SET_SELECT_CALENDAR`, value)
        this.refetchEvents()
      },
    },
    profilePartnerId: {
      get() {
        return store.state[this.storeModuleName].profilePartnerId
      },
      set(value) {
        store.commit(`${this.storeModuleName}/SET_SELECT_PROFILEPARTNERID`, value)
        this.refetchEvents()
      },
    },
    respDataPartner() {
      return store.state[this.storeModuleName].respDataPartner != null ? store.state[this.storeModuleName].respDataPartner.data : []
    },
    queriedItemsPartner() {
      return store.state[this.storeModuleName].respDataPartner != null ? store.state[this.storeModuleName].respDataPartner.max : 0
    },
  },
  created() {
    // Register module
    store
      .dispatch(`${this.storeModuleName}/getPartner`, {
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
        calendars: this.selectedCalendars,
      })
      .then(result => {
        console.log('fetch Success : ', result)
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.onSearchPartner = debounce(this.onSearchPartner, 500)
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    onSearchPartner(query) {
      this.searchPartner = query
      this.currentPagePartner = 1
      const obj = {
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
      }
      store
        .dispatch(`${this.storeModuleName}/getPartner`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
}
</script>
